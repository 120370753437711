/*===================
Blog Styles 
=====================*/
.fontMontserrat {
  font-family: "Montserrat", sans-serif;
}

.font10 {
  font-size: 10px;
  @media #{$large-mobile} {
    font-size: 7px;
  }
  @media #{$small-mobile} {
    font-size: 7px;
  }
}

.sintelTitle {
  color: #1aa6ec !important;
  font-weight: 700 !important;
}

.titleCenter {
  text-align: center;
  font-size: 60px;
  font-weight: 900;
  color: #7f7f81;
  padding-bottom: 30px;
  padding-top: 30px;
  .sintelTitle {
    color: #1aa6ec;
  }
  @media #{$large-mobile} {
    font-size: 30px !important;
    padding-bottom: 0px;
  }

  @media #{$small-mobile} {
    font-size: 30px !important;
    padding-bottom: 0px;
  }
}

.justifyText {
  text-align: justify;
}

.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.ellipsis.fontMontserrat p {
  font-size: 14px;
}

// issue on MOBILE VIEW
// .ellipsis::after {
//   content: "";
//   position: absolute;
//   bottom: -65px;
//   width: 100%;
//   height: 132px; /* Adjust the height of the blurred area */
//   backdrop-filter: blur(1px); /* Adjust the blur strength */
// }

.card {
  width: 400px;
  margin: 10px;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.blog-style--1 {
  position: relative;
  margin-bottom: 20px;
  @extend %transition;
  overflow: hidden;
  border-radius: 6px;
  .thumbnail {
    a {
      display: block;
      position: relative;
      z-index: 1;
      img {
        @extend %transition;
      }
      &::after {
        background: linear-gradient(to bottom, #0085c0 0%, #abaaa7 100%);
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        content: "";
        z-index: 1;
        opacity: 0;
        @extend %transition;
        @media #{$large-mobile} {
          opacity: 0.85;
          top: 0;
        }
      }
      @media #{$large-mobile} {
        padding-top: 30px;
      }
      @media #{$small-mobile} {
        padding-top: 30px;
      }
    }
  }
  .content {
    position: absolute;
    bottom: -92px;
    width: 100%;
    padding: 50px 35px;
    z-index: 3;
    @extend %transition;
    @media #{$large-mobile} {
      bottom: 0;
    }
    @media #{$large-mobile} {
      padding: 30px 20px;
    }
    &::before {
      position: absolute;
      content: "";
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, #0085c0 70%);
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      @extend %transition;
      z-index: -1;
      @media #{$large-mobile} {
        opacity: 0;
      }
    }
    p {
      &.blogtype {
        font-size: 14px;
        margin-bottom: 5px;
        color: #fff;
      }
    }
    h4 {
      &.title {
        margin-bottom: 0;
        a {
          color: #ffffff;
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
          @extend %transition;
          @media #{$large-mobile} {
            font-size: 18px;
          }
          @media #{$small-mobile} {
            font-size: 18px;
          }
        }
      }
    }
    .blog-btn {
      @extend %transition;
      margin-top: 35px;
      transition: 0.7s;
      opacity: 0;
      visibility: hidden;

      @media #{$large-mobile} {
        margin-top: 22px;
      }
      @media #{$large-mobile} {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &:hover {
    .content {
      bottom: 0;
      &::before {
        opacity: 0;
      }
    }
    .thumbnail {
      a {
        img {
          transform: scale(1.1);
        }
        &::before {
          opacity: 0;
        }
        &::after {
          opacity: 0.85;
          top: 0;
        }
      }
    }
    .content {
      .blog-btn {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
